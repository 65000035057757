import React, { FC, useEffect, useRef, useState } from 'react'
import EventDispatcher from "../../helper/EventDispatcher";
import Translation from "../../helper/Translation";
import PasswordInstructions, { PasswordRule } from "./PasswordInstructions";

interface AuthPasswordInputProps {
  withForgetPasswordButton?: 'email' | 'mobile';
  withValidationInstructions?: boolean;
  isValid?: (valid: boolean)=> void;
  label?: string;
  withPasswordConfirmation?: boolean;
  passwordConfirmation?: string;
  passwordChangeHandler?: (password: string) => void;
  id?: string;
  autoComplete?: string;
}

const AuthPasswordInput: FC<AuthPasswordInputProps> = ({withForgetPasswordButton,label,isValid,withValidationInstructions= false,passwordChangeHandler,withPasswordConfirmation= false,passwordConfirmation,id,autoComplete,...props})=> {
  const password = useRef(null)
  const [passwordValue , setPasswordValue] = useState<string>('')
  const [showPasswordInstructions , setShowPasswordInstructions] = useState<boolean>(false)
  const [passwordRules , setPasswordRules] = useState<PasswordRule[]>([])
  const [passwordIsValid , setPasswordIsValid] = useState<boolean>(true)


  useEffect(()=> {
    const rules = PasswordInstructions.rules(passwordValue,withPasswordConfirmation , passwordConfirmation)
    setPasswordRules(rules)
    const passwordValid = rules.filter(rule => rule.isValid ).length == rules.length
    setPasswordIsValid(passwordValid)
    passwordChangeHandler && passwordChangeHandler(passwordValue)
    isValid && isValid(passwordValid)

  } , [passwordValue ,passwordConfirmation])






  const passwordInstructions = ()=> {
    return (
      <div className={`absolute ${showPasswordInstructions ? 'flex': 'hidden'} rounded-md left-0 bottom-full mb-1  w-full text-left bg-white-90 shadow-md z-40 bg-white p-4`}
           onMouseLeave={()=> setShowPasswordInstructions(false)}
           onMouseEnter={()=> setShowPasswordInstructions(true)}
      >
        <div className={`flex flex-col gap `}>
          {passwordRules.map((rule: PasswordRule , index: number)=> {
            return (
              <div key={index} className={`flex gap-1 items-center`}>
                  <i className={`${rule.isValid ? 'ri-check-line text-emerald-500' :'ri-close-line text-red-400 '} text-xl`} />
                  <span className={`truncate text-coolGray-800 text-xs leading-4 ms-2 text-normal`}>{rule.title}</span>
              </div>
            )
          }) }

        </div>
      </div>
    );
  }
  return (
    <div className="w-full">
      <div className={`relative flex items-center gap-1`}>
        <label className="block text-gray-700 text-start text-sm leading-5 font-normal" htmlFor={id || "password"}>{label || Translation.t('inputs.password.label')}</label>
        {withValidationInstructions && (<i className={`ri-information-fill ${passwordIsValid ? 'text-coolGray-500': 'text-red-500'}`}
                                           onMouseLeave={()=> setShowPasswordInstructions(false)}
                                           onMouseEnter={()=> setShowPasswordInstructions(true) }/> ) }
        {withValidationInstructions &&  passwordInstructions()}
      </div>
      <div className="mt-1 flex flex-col">
        <div className="relative">
          <input  className="form-input border border-coolGray-300 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  type="password"
                  ref={password}
                  placeholder={Translation.t('inputs.password.placeholder')}
                  required={true}
                  id={id || "password" }
                  {...(autoComplete ? {autoComplete} : {})}
                  onChange={(e)=> setPasswordValue(e.target.value)}
                  name="password"
          />
          <i className={`ri-eye-line cursor-pointer absolute top-1/4 end-2 pb-1 fill-current text-coolGray-400 h-6 w-6`} onClick={()=>
            password.current.type = (password.current.type ==  'text' ? 'password': 'text')
          } />
        </div>

        {withForgetPasswordButton && (
          <span className="mt-1.5 text-start cursor-pointer text-sm leading-5 text-normal text-coolGray-400"
                onClick={()=> {
                  EventDispatcher.dispatch('open-auth-modal' , { mode: `forget-with-${withForgetPasswordButton}` })
                }}>{Translation.t('texts.forget_password_link')}</span>
        )}
      </div>
    </div>
  )
}

export  default AuthPasswordInput
