import React, { FC, useRef, useState } from 'react'
import AuthModalHeader from "./AuthModalHeader";
import Translation from "../../helper/Translation";
import Divider from "../Divider";
import AuthButton from "./AuthButton";
import EventDispatcher from "../../helper/EventDispatcher";
import AuthMobileInput from "./AuthMobileInput";
import { Country } from "@shobbak/react-services/dist/Entities";
import get from "lodash/get"
import last from "lodash/last"
import ReCAPTCHA from "react-google-recaptcha";
import OtpForm from "./OtpForm";
import BackendCall from "../BackendCall";
import { UserMobile } from '@shobbak/react-services/dist/Entities'
import AuthApi from "./AuthApi";
import AuthInput from "./AuthInput";
import AuthPasswordInput from "./AuthPasswordInput";


interface RegisterProps {
  onClose: ()=> void ;
  locale: string ;
  token: string ;
  country: string;
  redirectUrl: string | null;
  recaptchaEnabled: boolean;
  enabledCountries: Country[];
  defaultCountry: Country
}

interface ErrorBagType {
  number: string,
  error?: string
}

const RegisterWithMobile: FC<RegisterProps> = ({defaultCountry,enabledCountries, onClose,locale, token,country ,redirectUrl, recaptchaEnabled = true}) => {

  const [errorBag , setErrorBag] = useState<ErrorBagType>({number: ''})
  const [formDisabled , setFormDisabled] = useState<boolean>(false)
  const [isMobileValid,setIsMobileValid] = useState<boolean>(false)
  const [isPasswordValid,setIsPasswordValid] = useState<boolean>(false)
  const [mobileData,setMobileData] = useState<UserMobile>(undefined)
  const recaptchaRef = useRef(null)



  const createUserMobile = async (mobile_number, country_id)=> {
    let params = {country_id: country_id, number: mobile_number}
    if (recaptchaEnabled) {
      // important to reset every time submit the from to reset the token of recaptcha
      await recaptchaRef.current.reset()
      let recaptcha_token = await recaptchaRef.current.executeAsync()
      params = { ...params, recaptcha_token: recaptcha_token }
    }
    setFormDisabled(true)
    BackendCall.i({ token, locale, pathPrefix: '/api/v2/' })
      .createUserMobileV2(params)
      .then((res)=> {
        setMobileData(res)
      })
      .catch(error=> {
        const errors = error?.jsonAPIErrorMessages()
        if(errors) {
          setErrorBag(errors)
        }
      }).finally(() => {
        setFormDisabled(false)
      })
  }

  const register = async (password , full_name)=> {
    let params = {password, full_name, country_id: mobileData.countryId, user_mobile_id: mobileData.id}
    if (recaptchaEnabled) {
      // important to reset every time submit the from to reset the token of recaptcha
      await recaptchaRef.current.reset()
      let recaptcha_token = await recaptchaRef.current.executeAsync()
      params = { ...params, recaptcha_token: recaptcha_token }
    }
    setFormDisabled(true)
    const api = new AuthApi(locale , country)
    api.register(params)
    .then(({url})=> {
      // @ts-ignore
      window.trackActivity({event: 'sign_up', processPayload: false, rawPayload: {}, shouldRedirect: false, callback: () => window.location.href =  redirectUrl || url  })
    })
    .catch(response=> {
      setErrorBag({ user_mobile: last(get(response,'user_mobiles.number')), error: get(response, 'error') })
    }).finally(() => {
      setFormDisabled(false)
    })
  }

  const renderMobileInputForm = () => {
    return <form onSubmit={(e)=> {
      e.preventDefault()
      if (formDisabled || !isMobileValid)return ;
      // @ts-ignore
      createUserMobile(e.target.mobile_number.value, e.target.country_id.value)
    }}>
      <div className={`mt-4 flex flex-col gap-2`}>
        <AuthMobileInput  isValid={setIsMobileValid}
                          countryCodes={enabledCountries}
                          defaultSelectedCountry={defaultCountry}
                          error={errorBag.number || errorBag?.base}
                          onChange={() => setErrorBag({number: ''})} />
      </div>

      <div className={`mt-4`}>
        <AuthButton label={Translation.t('buttons.verify')} disabled={(formDisabled ||!isMobileValid)} />
      </div>

      {recaptchaEnabled && (
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={window.env?.RECAPTCHA_KEY}
        />
      )}
    </form>
  }

  const renderOtpForm = () => {
    return <OtpForm
      locale={locale}
      token={token}
      mobileData={mobileData}
      resendVerificationCode={() => createUserMobile(mobileData.number, mobileData.countryId)}
      onSuccess={(payload) => {
        setMobileData(payload)
    }} />
  }

  const renderUserDataForm = () => {
    return <form onSubmit={(e)=> {
      e.preventDefault()
      if (formDisabled || !isPasswordValid)return ;
      // @ts-ignore
      register(e.target.password.value , e.target.full_name.value)
    }}>
      <div className={`mt-4 flex flex-col gap-2`}>
        <AuthInput placeholder={Translation.t('inputs.full_name.placeholder')} name="full_name" label={Translation.t('inputs.full_name.label')} error={errorBag.error || ''}  />
        <AuthPasswordInput withValidationInstructions id="new-password" autoComplete="new-password"
                           isValid={setIsPasswordValid}  />
      </div>

      <div className={`mt-4`}>
        <AuthButton label={Translation.t('buttons.sign_up')} disabled={(formDisabled ||!isPasswordValid)} />
      </div>

      {recaptchaEnabled && (
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={window.env?.RECAPTCHA_KEY}
        />
      )}

    </form>
  }

  return (
    <>
      <div className={`md:p-6 p-4`}>
        <div className={`flex flex-col gap-3`}>
          <AuthModalHeader onClose={onClose} title={Translation.t('screens.sign_up.title')} subTitle={ Translation.t('texts.register_subtitle') } headerIconName={`ri-user-add-line`}  />
        </div>
        {!!mobileData ? (mobileData.verified ? renderUserDataForm() : renderOtpForm()) : renderMobileInputForm()}
      </div>

      {(!mobileData || mobileData.verified) && <div className="flex gap-1 justify-center items-center py-4 bg-coolGray-200  text-center">
          <p className="text-coolGray-700 text-sm leading-5 font-normal">
            {Translation.t('texts.already_have_account')}
          </p>
          <p className="text-emerald-500 cursor-pointer" onClick={()=> EventDispatcher.dispatch('open-auth-modal' , {mode: 'login'})}>
            {Translation.t('texts.login_now')}
          </p>
        </div>
      }
    </>
  )

}

export default RegisterWithMobile
