import React, { FC } from 'react'
import Translation from "../../helper/Translation";

interface AuthEmailInputProps {
  error: string;
}

const AuthEmailInput: FC<AuthEmailInputProps> = ({error,...props})=> {

 return (
     <div>
       <label className="block text-gray-700 text-start text-sm leading-5 font-normal" htmlFor="email">{Translation.t('inputs.email.label')}</label>
       <input className="mt-1 form-input border border-coolGray-300 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              type="email"
              placeholder={Translation.t('inputs.email.placeholder')}
              required={true}
              name="email"
              autoComplete="email"
              id="email" />
       {error && (<span className="text-red-500 text-xs text-start leading-4 font-normal">{error}</span> )}
     </div>
 )

}

export default AuthEmailInput
